:root {
  --color-base: "#142A2E";
}

.scrollBar-hidden::-webkit-scrollbar{
  width: 0;
}

html{
  scroll-behavior: smooth;
}
